import { cache, createAsync, redirect, useNavigate } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  For,
  JSX,
  Match,
  onCleanup,
  onMount,
  Show,
  Suspense,
  Switch,
} from "solid-js";
import {
  assetBhimUPI,
  creditCardSheildIcon,
  invoiceIcon,
  walletIcon,
} from "~/assets/assets";
import { useModal } from "~/components/modal";
import ProformaInvoiceGenerationModal from "~/components/procurement/proforma_invoice_generation_modal";
import { useProcurement } from "~/context/procurement";
import { payOrderStandardCheckout } from "~/data/products";
import { PaymentLoader } from "~/screens/payment_options/payment_loader";
import {
  getProcurementProfileData,
  procurementOrderInitiate,
} from "~/server/apis/client_apis";
import {
  getProcurementCheckoutRouteData,
  ProcurementCheckoutRouteData,
} from "~/server/data/procurement_checkout_route_data";
import { goBack } from "~/shared_states/modal";
import { snackApiCallWrapper } from "~/shared_states/snackbar";
import { toIndianNumber } from "~/utils/number";
import { openRazorpayCheckout } from "~/utils/third_party/razorpay";
import { Divider } from "~/widgets/divider";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

const getProcurementCheckoutRouteData$C = cache(
  getProcurementCheckoutRouteData,
  "procurement_checkout_data"
);

enum PaymentMode {
  WALLET,
  UPI,
  CARD,
  PREMIUM_CARD,
}

export default function checkout() {
  const { procurement, clearCart } = useProcurement();

  const routeData: Accessor<ProcurementCheckoutRouteData | undefined> =
    createAsync<ProcurementCheckoutRouteData>(() => {
      return getProcurementCheckoutRouteData$C({
        simulateProp: {
          products: procurement.cart.products.map((product) => ({
            productId: product.productId,
            amount: product.amount,
            denominationDetails: [
              {
                denomination: product.denomination,
                quantity: product.quantity,
              },
            ],
          })),
          totalAmount: procurement.cart.totalAmount,
        },
      });
    });

  const { openModal, closeModal } = useModal()!;

  const [initOrderJob, setInitOrderJob] = createSignal(false);
  const [error, setError] = createSignal<string | null>(null);
  const [paymentMode, setPaymentMode] = createSignal<PaymentMode | null>(null);

  const navigator = useNavigate();

  let notesRef: HTMLInputElement | undefined;

  onMount(() => {
    if (procurement.cart.totalAmount == 0) {
      return navigator("/catalogue", { replace: true });
    }
  });

  onCleanup(() => {
    if (procurement.cart.proformaInvoiceId) {
      clearCart();
    }
  });

  const confirmationModal = async (props: { totalPayableAmount: number }) => {
    openModal(() => {
      return (
        <div class="flex w-full flex-col justify-center">
          <div class="mb-6  mt-8 flex flex-col items-center justify-center">
            <div class="mb-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#DAF7E8]">
              <PhosphorIcon
                name="shopping-cart-simple"
                fontSize={48}
                class="text-successDark"
                size="light"
              />
            </div>
            <p class="text-f12Bold uppercase text-successDark">Confirm order</p>

            <Show
              when={paymentMode() == PaymentMode.WALLET}
              fallback={
                <p class="w-4/5 text-center text-h3 text-textDark">
                  Proceed to pay ₹{props.totalPayableAmount}
                </p>
              }
            >
              <p class="w-4/5 text-center text-h3 text-textDark">
                ₹{props.totalPayableAmount} will be debited from your squid
                wallet
              </p>
              <p class="text-medium text-textNormal">
                Current balance: ₹{procurement.balance}
              </p>
            </Show>
            <Show when={error()}>
              <div class="text-medium text-errorDark">{error()}</div>
            </Show>
          </div>
          <div class="w-full border-t border-baseTertiaryDark"></div>
          <div class="mx-2 mt-4 h-10">
            <input
              ref={notesRef}
              type="text"
              class="h-10  w-full rounded-lg bg-baseTertiaryMedium p-2  text-medium text-textDark placeholder:text-medium placeholder:text-textNormal focus:border focus:border-black"
              placeholder="Add a note (Optional)"
              maxLength={40}
            />
          </div>
          <div class=" p-2 pt-4">
            <button
              class={`w-full rounded-lg px-4 py-2.5 text-buttonMedium text-white ${
                initOrderJob() ? "bg-gray-400 opacity-50" : "bg-successDark"
              }`}
              onClick={() => {
                if (initOrderJob()) return;
                initiateOrder();
              }}
            >
              Confirm & pay
            </button>
          </div>
        </div>
      );
    }, "md:w-[380px]");
  };

  const upiPaymentLoader = async (props: {
    amount: number;
    onPaymentComplete: () => void;
    onPaymentFailed: () => void;
    paymentOrderId: string;
    orderId: string;
  }) => {
    openModal(() => {
      return (
        <PaymentLoader
          amount={props.amount}
          onPaymentComplete={props.onPaymentComplete}
          onPaymentFailed={props.onPaymentFailed}
          orderId={props.paymentOrderId}
          brandImageUrl=""
          reInit={() => {}}
          onClose={() => {
            closeModal();
            setInitOrderJob(false);
          }}
          mode="squid"
          procurementOrderId={props.orderId}
        />
      );
    }, "md:w-full");
  };

  const initiateOrder = async () => {
    setInitOrderJob(true);
    try {
      const result = await procurementOrderInitiate({
        totalAmount: procurement.cart.totalAmount,
        products: procurement.cart.products.map((product) => ({
          productId: product.productId,
          amount: product.amount,
          denominationDetails: [
            {
              denomination: product.denomination,
              quantity: product.quantity,
            },
          ],
        })),
        notes: notesRef?.value,
        paymentMode:
          paymentMode() == PaymentMode.WALLET ||
          paymentMode() == PaymentMode.UPI
            ? "UPI"
            : paymentMode() == PaymentMode.CARD
              ? "CARD"
              : "PREMIUM_CARD",
        useWallet: paymentMode() == PaymentMode.WALLET,
        proformaInvoiceId: procurement.cart.proformaInvoiceId,
      });
      closeModal();
      if (result) {
        if (result.paymentDetails) {
          if (paymentMode() == PaymentMode.UPI) {
            // return upiPaymentLoader({
            //   amount: result.paymentDetails.amount,
            //   paymentOrderId: result.paymentDetails.id,
            //   onPaymentComplete: () => {
            //     clearCart();
            //     navigator("/order/" + result.id, { replace: true });
            //   },
            //   onPaymentFailed: () => {},
            //   orderId: result.id,
            // });
            const order = await snackApiCallWrapper(
              payOrderStandardCheckout(result.paymentDetails.id)
            );
            return openRazorpayCheckout({
              amount: result.paymentDetails.amount,
              email: procurement.userProfile.email,
              mode:
                paymentMode() === PaymentMode.CARD ||
                paymentMode() === PaymentMode.PREMIUM_CARD
                  ? "CARD"
                  : "UPI",
              orderId: order.providerOrderId,
              phone: procurement.userProfile.phone,
              onSuccess: () => {
                clearCart();
                navigator("/order/" + result.id, { replace: true });
              },
              onDismiss() {
                setInitOrderJob(false);
              },
            });
          } else {
            const order = await snackApiCallWrapper(
              payOrderStandardCheckout(result.paymentDetails.id)
            );
            return openRazorpayCheckout({
              amount: result.paymentDetails.amount,
              email: procurement.userProfile.email,
              mode: paymentMode() === PaymentMode.CARD ? "CARD" : "UPI",
              orderId: order.providerOrderId,
              phone: procurement.userProfile.phone,
              onSuccess: () => {
                clearCart();
                navigator("/order/" + result.id, { replace: true });
              },
              onDismiss() {
                setInitOrderJob(false);
              },
            });
          }
        }
        clearCart();
        navigator("/order/" + result.id, { replace: true });
      }
    } catch (error: any) {
      console.error("Failed to initiate order:", error);
      setError(error.message);
    }
    setInitOrderJob(false);
  };

  onCleanup(() => {
    setError(null);
  });

  const address = createMemo(
    () => routeData()?.businessDetails.details.address
  );

  return (
    <Suspense
      fallback={
        <div class="flex-1 items-center justify-center">
          <DottedLoader color="#999" />
        </div>
      }
    >
      <div class="flex h-full w-full items-start justify-center overflow-y-auto bg-baseTertiaryMedium py-8">
        <div class="w-full max-w-[944px]">
          <div class="mb-4 flex">
            <PhosphorIcon
              name="arrow-left"
              fontSize={18}
              class="cursor-pointer rounded-full bg-white p-2"
              onClick={() => {
                goBack();
              }}
            />
            <div class="px-3 text-h4 text-textDark">Proceed to pay</div>
          </div>
          <Divider />
          <div class="flex">
            <div class="my-6 flex w-full flex-col gap-3">
              <div class=" text-smallBold text-textNormal">PAY NOW</div>
              {paymentOption({
                title: "Wallet",
                subtitle: "Balance: ₹" + toIndianNumber(procurement.balance),
                payableAmount:
                  procurement.cart.totalAmount - procurement.cart.totalDiscount,
                icon: walletIcon,
                onClick: () => {
                  setPaymentMode(PaymentMode.WALLET);
                  confirmationModal({
                    totalPayableAmount:
                      procurement.cart.totalAmount -
                      procurement.cart.totalDiscount,
                  });
                },
              })}
              {paymentSection()}

              <Show when={!procurement.cart.proformaInvoiceId}>
                <div class="mt-4 text-smallBold text-textNormal ">
                  PAY VIA FINANCE TEAM
                </div>
                {PISection()}
              </Show>
            </div>
            <div class="flex w-full max-w-[280px] flex-col">
              {cartSummary()}
              <Show when={!procurement.cart.proformaInvoiceId}>
                {billingDetails()}
              </Show>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );

  function paymentSection() {
    return (
      <For each={routeData()?.simulate.data}>
        {(option) => (
          <Switch>
            <Match when={option.paymentMode == "UPI"}>
              {paymentOption({
                title: "UPI",
                subtitle: "Via any UPI app",
                payableAmount: option.totalDueAmount,
                icon: assetBhimUPI,
                onClick: () => {
                  setPaymentMode(PaymentMode.UPI);
                  confirmationModal({
                    totalPayableAmount: option.totalDueAmount,
                  });
                },
              })}
            </Match>
            <Match when={option.paymentMode == "PREMIUM_CARD"}>
              {paymentOption({
                title: "Premium Credit card",
                subtitle:
                  option.additionCharges.transactionFees.percentage +
                  "% charges applicable",
                payableAmount: option.totalDueAmount,
                icon: creditCardSheildIcon,
                fee: true,
                onClick: () => {
                  setPaymentMode(PaymentMode.PREMIUM_CARD);
                  confirmationModal({
                    totalPayableAmount: option.totalDueAmount,
                  });
                },
              })}
            </Match>
            <Match when={option.paymentMode == "CARD"}>
              {paymentOption({
                title: "Credit card",
                subtitle:
                  option.additionCharges.transactionFees.percentage +
                  "% charges applicable",
                payableAmount: option.totalDueAmount,
                icon: creditCardSheildIcon,
                fee: true,
                onClick: () => {
                  setPaymentMode(PaymentMode.CARD);
                  confirmationModal({
                    totalPayableAmount: option.totalDueAmount,
                  });
                },
              })}
            </Match>
          </Switch>
        )}
      </For>
    );
  }

  function paymentOption(props: {
    icon: string;
    title: string;
    subtitle: string;
    onClick: JSX.EventHandlerUnion<HTMLDivElement, MouseEvent> | undefined;
    payableAmount: number;
    fee?: boolean;
  }) {
    return (
      <div class="flex max-w-[640px] gap-3 rounded-[12px] border border-baseTertiaryDark bg-white p-4">
        <div class="flex h-10 w-10 items-center justify-center rounded-full border border-baseTertiaryDark">
          <HubbleImage src={props.icon} alt="" class="h-6 w-6 object-contain" />
        </div>
        <div class="flex grow flex-col">
          <div class="text-bold">{props.title}</div>
          <div
            class={`text-f12 ${props.fee ? "text-errorDark" : "text-black"}`}
          >
            {props.subtitle}
          </div>
        </div>
        <div
          class="cursor-pointer rounded-[8px] border border-black px-4 py-[10px] text-mediumBold"
          onClick={props.onClick}
        >
          {"Pay ₹" + toIndianNumber(props.payableAmount)}
        </div>
      </div>
    );
  }

  function billingDetails() {
    return (
      <div class=" mt-3 flex h-fit w-full max-w-[280px] flex-col gap-2 rounded-[12px] border border-baseTertiaryDark bg-white p-3">
        <div class=" mb-1 text-smallBold text-textNormal">BILLING DETAILS</div>
        <div class="text-f12">
          <span class="text-f12Bold">{"Name: "}</span>
          {routeData()?.businessDetails.businessName}
        </div>
        <div class="text-f12">
          <span class="text-f12Bold">{"GST No.: "}</span>
          {routeData()?.businessDetails.details.gstNumber}
        </div>
        <div class="text-f12">
          <span class="text-f12Bold">{"Address: "}</span>
          {address()?.address1}
          {", "}
          {address()?.address2}
          {", "}
          {address()?.place}
          {", "}
          {address()?.state}
          {", "}
          {address()?.pincode}
        </div>
      </div>
    );
  }
  function cartSummary() {
    return (
      <div class=" mt-[51px] h-fit w-full  rounded-[12px] border border-baseTertiaryDark bg-white p-3">
        <div class="text-smallBold text-textNormal">SUMMARY</div>
        <div class="flex w-full items-end">
          <div class="flex w-full flex-col">
            <div class="mt-3 text-f12 text-textNormal">To pay</div>
            <div class="grow text-f12Bold text-textDark">Gift card value</div>
          </div>
          <div class="text-bold text-textDark">
            ₹{toIndianNumber(procurement.cart.totalAmount)}
          </div>
        </div>
        <div class="py-2">
          <Divider />
        </div>
        {listProducts()}
      </div>
    );
  }
  function PISection() {
    return (
      <div class="flex max-w-[640px] gap-4 rounded-[12px] border border-baseTertiaryDark bg-white p-4">
        <div class="flex h-10 w-10 items-center justify-center rounded-full border border-baseTertiaryDark">
          <HubbleImage
            src={invoiceIcon}
            alt="invoice-icon"
            class="h-6 w-6 object-contain"
          />
        </div>
        <div class="flex grow items-end justify-end">
          <div class="flex grow flex-col items-start justify-start">
            <div class="mb-1 text-bold">Generate Proforma Invoice (PI)</div>
            {PIFlowDetails()}
          </div>
          <div
            class="h-min w-[126px] cursor-pointer rounded-[8px] border border-black px-4 py-2 text-center text-mediumBold"
            onClick={() => {
              openModal(() => {
                return ProformaInvoiceGenerationModal({
                  simulate: {
                    totalAmount: procurement.cart.totalAmount,
                    products: procurement.cart.products.map((product) => ({
                      productId: product.productId,
                      amount: product.amount,
                      denominationDetails: [
                        {
                          denomination: product.denomination,
                          quantity: product.quantity,
                        },
                      ],
                    })),
                  },
                });
              }, "md:w-fit p-0");
            }}
          >
            Generate
          </div>
        </div>
      </div>
    );
  }

  function listProducts() {
    return (
      <div class="flex flex-col gap-2">
        <For each={procurement.cart.products}>
          {(product) => (
            <div class="flex">
              <div class="grow text-f12Bold">
                {product.detail.brandName +
                  " ₹" +
                  toIndianNumber(product.denomination) +
                  " x " +
                  product.quantity}
              </div>
              <div class="text-f12">{"₹" + toIndianNumber(product.amount)}</div>
            </div>
          )}
        </For>
      </div>
    );
  }

  function PIFlowDetails() {
    const flow = [
      "Generate a Performa invoice",
      "Your finance team makes the payment",
      "We credit funds to your Squid wallet",
      "Use Squid wallet to complete order",
    ];
    return (
      <div class="flex flex-col items-start justify-start">
        <For each={flow}>
          {(pointer) => (
            <div class="mt-2 flex items-start  gap-1">
              <div class="flex h-[18px] w-[18px] items-center  justify-center rounded-full bg-baseTertiaryMedium text-smallBold">
                {flow.indexOf(pointer) + 1}
              </div>
              <div class=" text-medium">{pointer}</div>
            </div>
          )}
        </For>
      </div>
    );
  }
}
